/**
 * Captures gclid parameter and stores in a cookie for contact form use
 * Initial Reference https://zackphilipps.com/store-gclid-cookie-send-to-hubspot/
 */

/**
 * Returns cookie by name
 * @param {string} name - cookie name
 * @returns {string} - cookie value
 */
function getCookie( name ) {
  var value = '; ' + document.cookie;
  var parts = value.split( '; chr_' + name + '=' );
  if ( parts.length === 2 ) {
    return parts.pop().split( ';' ).shift();
  }
}

/**
 * Sets cookie by name, value and expiry in days
 * @param {string} name - cookie name
 * @param {string} value - cookie value
 * @param {int} days - cookie expiry in days
 * @returns {void}
 */
function setCookie( name, value, days ) {
  var date = new Date(),
      expires;
  date.setTime( date.getTime() + ( days * 24 * 60 * 60 * 1000 ) );
  expires = '; expires=' + date.toGMTString();
  document.cookie = 'chr_' + name + '=' + value + expires + ';path=/';
}

/**
 * Gets URL param by name
 * @param {string} paramName - parameter name
 * @returns {string} - parameter value
 */
function getParam( paramName ) {
  var match = RegExp( '[?&]' + paramName + '=([^&]*)' ).exec( window.location.search );
  return match && decodeURIComponent( match[1].replace( /\+/g, ' ' ) );
}

/**
 * Adds Cookie Value to hidden fields in forms
 * @param {string} fieldParam - parameter name to track
 * @param {Array} inputs - array of HTML hidden inputs to bind to parameter value
 * @returns {void}
 */
function assignCookieValueToFormInput( fieldParam, inputs ) {
  let field = getCookie( fieldParam ),
      length = inputs.length,
      index = 0;
  if ( field && length ) {
    for ( index = 0; index < length; index++ ) {
      inputs[index].value = field;
    }
  }
}

/**
 * Adds tracking parameters to Cookies.  Also sets landing_url into cookie 
 * when gclid is used as the fieldParam.
 * @param {string} fieldParam - parameter name to track
 * @param {string} fieldType - input field types to bind
 * @param {int} cookieExpiry - cookie expiry in days
 * @returns {void}
 */
function assignTrackingParamToCookie( fieldParam, fieldType, cookieExpiry ) {
  let field = getParam( fieldParam ),
      inputs,
      isAdwords = 'gclid' === fieldParam,
      landingInputs = [];
  if ( field ) {
    setCookie( fieldParam, field, cookieExpiry );

    // If it is from adwords, add landing page URL
    if ( true === isAdwords ) {
      setCookie( 'landing_url', window.location.href, cookieExpiry );
    }
  }
  inputs = document.querySelectorAll(
    'input[type="' + fieldType + '"][name="' + fieldParam + '"]'
  );
  assignCookieValueToFormInput( fieldParam, inputs );

  // If it is from adwords, add landing page URL value to forms
  if ( true === isAdwords ) {
    landingInputs = document.querySelectorAll(
      'input[type="hidden"][name="landing_url"]'
    );
    assignCookieValueToFormInput( 'landing_url', landingInputs );
  }
}

/**
 * Adds parameter tracking on load
 * @returns {void}
 */
function bindTrackingParams() {
  assignTrackingParamToCookie( 'gclid', 'hidden', 30 );
  assignTrackingParamToCookie( 'utm_source', 'hidden', 30 );
  assignTrackingParamToCookie( 'utm_medium', 'hidden', 30 );
  assignTrackingParamToCookie( 'utm_campaign', 'hidden', 30 );
}

bindTrackingParams();
