import animateScrollTo from 'animated-scroll-to';
let screenSize = require( '../utilities/media' );
let verticalOffset;

const anchorClick = () => {
  
  if ( matchMedia( screenSize.desktop ).matches ) {
    verticalOffset = -66;
  } else {
    verticalOffset = -58;
  }

  $( "a[href^='#']" ).not( "a[href='#'], .tabs a, a[href='#ict']" ).click( function() {
    animateScrollTo( document.querySelector( $( this ).attr( 'href' ) ), {
      verticalOffset,
      speed: 500,
    });
  });

};

jQuery( () => {
  anchorClick();
});
