/*
 * Home JS file for JS imports.
 *
 * @author Julian Chan <julian.chan@chromatix.com.au>
 */
require( './layout/header' );
require( './layout/footer' );
require( './layout/slide-out-form' );
require( './pages/home' );
require( './components/testimonial-slider-part' );
require( './effects/functions' );
require( './effects/home' );
require( './utilities/scroll-to' );
require( './utilities/tracking-param-to-cookie.js' );
require( './utilities/form-ajax' );
require( './utilities/ie-detect' );
require( './utilities/recaptcha-callback' );
require( './external/wp-embed.js' );
require( './utilities/video' );

// Uncomment to enable browser detection tools on this site.
// For emergency use only. This should not be used unless there is no other way!
//require( './utilities/browser-detect' );
