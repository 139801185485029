let screenSize = require( '../utilities/media' );

let homeBannerLayersParallax = () => {

  let windowHeight = jQuery( window ).height();
  let st = jQuery( window ).scrollTop();

  jQuery( '.home-banner .layer' ).each( function() {

    let homeBannerOffsetTop = jQuery( '.home-banner' ).offset().top;

    let speed = jQuery( this ).attr( 'data-speed' );

    let startPostion = -windowHeight * speed;

    let layerPosition = 0;

    let ZERO = 0;

    if ( st + windowHeight > homeBannerOffsetTop &&
      st < homeBannerOffsetTop + jQuery( '.home-banner' ).height() ) {

      layerPosition = startPostion +
        ( st + windowHeight - homeBannerOffsetTop ) * speed;

      if ( ZERO < layerPosition ) {
        jQuery( this ).css( 'transform', 'translate3d(0,-' + layerPosition + 'px,0)' );
      } else {
        jQuery( this ).css( 'transform', 'translate3d(0,0,0)' );
      }

    }

  });

};

let leavesParallax = () => {

  const windowHeight = jQuery( window ).height();
  const st = jQuery( window ).scrollTop();
  const loadingAllLeaves = 7;

  if ( loadingAllLeaves !== jQuery( '.leaves-group .leaf.load' ).length ) {
    return;
  }

  jQuery( '.leaves-group .leaf.load' ).each( function() {

    const homeBanerTop = jQuery( '.home-banner' ).offset().top;
    const speed = jQuery( this ).attr( 'data-speed' );
    const endAngle = jQuery( this ).attr( 'data-end-angle' );
    const startPostion = -windowHeight * speed;
    let leafPosition = 0;

    if ( st + windowHeight > homeBanerTop &&
      st < homeBanerTop + jQuery( '.home-banner' ).height() ) {

      leafPosition = -( startPostion +
        ( st + windowHeight - homeBanerTop ) * speed );
      jQuery( this ).css( 'transform',
        `translate3d(0, ${leafPosition}px, 0) rotate(${endAngle}deg)` );
    }

  });

};

let leavesRotation = () => {

  jQuery( '.leaves-group .leaf' ).each( function() {

    const startAngle = jQuery( this ).attr( 'data-start-angle' );
    const endAngle = jQuery( this ).attr( 'data-end-angle' );
    const duration = jQuery( this ).attr( 'data-duration' );
    const timeDelay = jQuery( this ).attr( 'data-delay' );

    setTimeout( function() {
      jQuery( this ).addClass( 'show' );
      jQuery( this ).animateRotate( startAngle, endAngle, duration, function() {
        jQuery( this ).addClass( 'load' );
      }.bind( this ) );
    }.bind( this ), timeDelay );

  });

};

let initPartnerSlider = () => {
  const ZERO = 0;
  const $partnersList = jQuery( '.partners-list' );
  if ( ZERO < $partnersList.length ) {
    $partnersList.slick({
      arrows:       false,
      dots:         true,
      infinite:     true,
      cssEase:      'linear',
      fade:         true,
      rows:         5,
      slidesPerRow: 4,
      responsive:   [
        {
          breakpoint: 1451,
          settings:   {
            slidesPerRow: 3
          }
        },
        {
          breakpoint: 768,
          settings:   {
            slidesPerRow: 2
          }
        }
      ]
    });
  }
};

let initTestimonialVideoSlider = () => {

  const ZERO = 0;
  const $testimonialVideoSlider = jQuery( '.testimonial-video-slider' );
  const sliderInitialized = $testimonialVideoSlider.hasClass( 'slick-initialized' ) ? true : false;

  if ( ZERO === $testimonialVideoSlider.length ) {
    return;
  }

  if ( matchMedia( screenSize.desktop ).matches && sliderInitialized ) {
    $testimonialVideoSlider.slick( 'unslick' );
  } else if ( ! matchMedia( screenSize.desktop ).matches && ! sliderInitialized ) {
    $testimonialVideoSlider.slick({
      arrows:         true,
      dots:           false,
      infinite:       true,
      cssEase:        'linear',
      centerMode:     true,
      centerPadding:  '16%',
      slidesToShow:   1,
      adaptiveHeight: true,
      responsive:     [ {
        breakpoint: 768,
        settings:   {
          slidesToShow: 1,
          centerMode:   false,
        }
      } ]
    });
  }

};

let toggleFaqList = () => {

  jQuery( '.single-faq .question' ).click( ( event ) => {
    jQuery( event.currentTarget ).toggleClass( 'opened' );
    jQuery( event.currentTarget ).next().slideToggle();
  });

};

jQuery( document ).ready( function() {

  initPartnerSlider();
  toggleFaqList();

  if ( ! matchMedia( screenSize.desktop ).matches ) {
    return;
  }
  leavesRotation();

  jQuery( window ).scroll( function() {
    homeBannerLayersParallax();
    leavesParallax();
  }).scroll();

});

jQuery( window ).resize( function() {
  initTestimonialVideoSlider();
}).resize();
