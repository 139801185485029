let screenSize = require( '../utilities/media' );

let initTestimonialSlider = () => {
  const ZERO = 0;
  const $testimonialSlider = jQuery( '.testimonial-slider' );
  if ( ZERO < $testimonialSlider.length ) {
    jQuery( '.testimonial-slider' ).slick({
      arrows:         false,
      dots:           true,
      infinite:       true,
      cssEase:        'linear',
      fade:           true,
      slidesToShow:   1,
      adaptiveHeight: true,
      speed:          1000
    });
  }
};

let sideImgParallax = () => {

  var windowHeight = jQuery( window ).height();
  var st = jQuery( window ).scrollTop();
  var tweakPoint = 500;

  if ( ! matchMedia( screenSize.desktop ).matches ) {
    return;
  }

  jQuery( '.image-group .side-image' ).each( function() {

    var sectionTop = jQuery( this ).parent().parent().offset().top - tweakPoint;
    var speed = jQuery( this ).attr( 'data-speed' );
    var stopPoint = parseInt( jQuery( this ).parent().attr( 'data-stop-point' ) );
    var startPostion = -windowHeight * speed;
    var imgPosition = 0;

    if ( st + windowHeight > sectionTop &&
      st < sectionTop + jQuery( this ).parent().parent().height() - stopPoint ) {

      imgPosition = -( startPostion +
        ( st + windowHeight - sectionTop ) * speed );
      jQuery( this ).css( 'transform', 'translate3d(0, ' + imgPosition + 'px, 0)' );
    }

  });

};

jQuery( () => {

  initTestimonialSlider();

  jQuery( window ).scroll( function() {

    sideImgParallax();

  }).scroll();

});
