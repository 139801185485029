let headerElements = {
  $hamburger:         jQuery( '.chr-hamburger-icon' ),
  $logoContainer:     jQuery( '.chr-logo-container' ),
  $header:            jQuery( '.chr-header' ),
  $responsiveMenu:    jQuery( '.chr-menu-wrapper' ),
  $whiteLightBox:     jQuery( '.chr-white-lightbox' ),
  $verticalLineGroup: jQuery( '.vertical-line-group' )
};

const $googleReviewImage = jQuery( '.google-review-image' );
const delayTime = 2000;

let hamburgerAnimation = () => {
  let $hamburgerWrapper = jQuery( '.chr-hamburger' ),
      $hamburgerIcon = headerElements.$hamburger;

  if ( $hamburgerIcon.length ) {

    $hamburgerWrapper.on( 'click', () => {

      $hamburgerIcon.toggleClass( 'opened' );
      headerElements.$responsiveMenu.toggleClass( 'opened' );
      headerElements.$whiteLightBox.toggleClass( 'opened' );
      headerElements.$verticalLineGroup.toggleClass( 'opened' );
      jQuery( 'body' ).toggleClass( 'rp-menu-opened' );
    });

  }
};

let toggleHeader = () => {

  let scrollTop = jQuery( window ).scrollTop();
  let absoluteTop = 0;

  if ( scrollTop <= absoluteTop ) {
    headerElements.$logoContainer.removeClass( 'scroll' );
    headerElements.$header.removeClass( 'scroll' );
  } else {
    headerElements.$logoContainer.addClass( 'scroll' );
    headerElements.$header.addClass( 'scroll' );
  }

};

const showGoogleReview = () => {

  if ( ! $googleReviewImage.length ) {
    return;
  }

  $googleReviewImage.addClass( 'show' );

};

const hideGoogleReview = () => {

  if ( ! $googleReviewImage.length ) {
    return;
  }

  let footerOffsetTop = jQuery( '.chr-footer-bottom' ).offset().top;
  let windowHeight = jQuery( window ).height();
  let st = jQuery( window ).scrollTop();

  if ( st >= footerOffsetTop - windowHeight ) {
    $googleReviewImage.addClass( 'bottom' );
  } else {
    $googleReviewImage.removeClass( 'bottom' );
  }

};

const scrollDownToNextSection = ( event ) => {
  let persistentNavH = 56;
  let to = jQuery( '.home-banner' ).height() - persistentNavH;
  let scrollTime = 1000;

  event.preventDefault();

  jQuery.scrollTo(
    to,
    scrollTime,
    { easing: 'easeInCubic' }
  );

};

jQuery( () => {

  hamburgerAnimation();

  setTimeout( ()=> {
    showGoogleReview();
  }, delayTime );

  jQuery( window ).scroll( () => {
    toggleHeader();
    hideGoogleReview();
  }).scroll();

  jQuery( '.scroll-down-btn, .scroll-down-banner-btn' ).on( 'click', scrollDownToNextSection );

});

