const $sectionTwoIconList = jQuery( '.section-two-icon-list' );

let homeTwoColFadeOnView = ( st, windowHeight ) => {

  jQuery( '.section-three-two-col-part' ).each( function() {
    jQuery( this ).find( '.col' ).fadeOnView( st, windowHeight );
  });

};

let ourExpertiseTextFadeOnView = ( st, windowHeight ) => {

  jQuery( '.section-three-heading-part, .section-three-bottom-part' ).each( function() {
    jQuery( this ).find( '.content-part' ).fadeOnView( st, windowHeight );
  });

  let interval = 300;
  let defaultStartPoint = 200;

  if ( ! jQuery( '.services-items-part' ).length ) {
    return;
  }

  jQuery( '.services-items-container' ).each( function() {
    let offsetTop = jQuery( this ).offset().top;
    let time = 0;

    if ( offsetTop < st + windowHeight - defaultStartPoint ) {

      jQuery( this ).find( '.single-item' ).each( function() {

        let $current = jQuery( this );

        setTimeout( function() {
          $current.addClass( 'show' ).delay( time );
        }, time );

        time = time + interval;

      });

    }

  });

};

let homeSectionTwoNumberIncrement = ( st, windowHeight ) => {

  if ( ! $sectionTwoIconList.length || $sectionTwoIconList.hasClass( 'number-load' ) ) {
    return;
  }

  let offsetTop, pos;

  offsetTop = $sectionTwoIconList.offset().top;
  pos = st + windowHeight;

  if ( offsetTop < pos ) {
    $sectionTwoIconList.addClass( 'number-load' );

    $sectionTwoIconList.find( '.item-number' ).each( ( index, el ) => {

      let value = jQuery( el ).attr( 'data-number' ),
          $el = jQuery( el ).find( '.number' );

      $({value: 0}).stop( true ).animate({value: value}, {
        duration: 3000,
        easing:   'swing',
        step:     function() {
          let val = Math.round( this.value );
          $el.text( val );
        }
      }).promise().done( () => {

        // Hard set the value after animation is done to be
        // Sure the value is correct
        $el.text( value );
      });
    });

  }

};

/*Handle some scroll events*/
jQuery( document ).ready( () => {

  /*Handle some scroll events*/
  jQuery( window ).scroll( () => {

    let windowHeight = jQuery( window ).height();
    let st = jQuery( window ).scrollTop();
    homeTwoColFadeOnView ( st, windowHeight );
    ourExpertiseTextFadeOnView( st, windowHeight );
    homeSectionTwoNumberIncrement( st, windowHeight );

  }).scroll();

});
