/*
 * Connects the form to the lambda function
 */
let screenSize = require( '../utilities/media' );

let getDeviceType = () => {
  let mediaType;

  if ( matchMedia( screenSize.mobile ).matches ) {
    mediaType = "Mobile";
  } else if ( matchMedia( screenSize.tablet ).matches ) {
    mediaType = "Tablet";
  } else {
    mediaType = "Desktop";
  }

  jQuery( 'form input.device-type' ).val( mediaType );
};

let formFieldFocus = () => {
  jQuery( '.control-wrap input, .control-wrap textarea' ).focusin( ( event ) => {
    jQuery( event.currentTarget ).parent().find( 'label' ).addClass( 'focus' );
  });
  jQuery( '.control-wrap input, .control-wrap textarea' ).focusout( ( event ) => {

    if ( jQuery( event.currentTarget ).val() ) {
      return;
    }

    jQuery( event.currentTarget ).parent().find( 'label' ).removeClass( 'focus' );
  });
};

let validateEmail = ( email ) => {
  const regx = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regx.test( String( email ).toLowerCase() );
};

let validatePhone = ( phone ) => {
  const regx = /^[+]?[0-9() -]*$/;
  return regx.test( String( phone ).toLowerCase() );
};

let updateContactFormTitle = () => {

  jQuery( '#contact-your-name, #contact-your-phone' ).change( ( )=>{
    const clientName = jQuery( '#contact-your-name' ).val();
    const clientPhone = jQuery( '#contact-your-phone' ).val();
    jQuery( '.contact-form #email-subject' )
      .val( `Footer Form Inquiry - ${clientName} - ${clientPhone}` );
  });

  jQuery( '.side-form-wrapper .project-form #ict-project-your-name, ' +
    '.side-form-wrapper .project-form #ict-project-your-phone' ).change( ( )=>{

    const clientName = jQuery( '.side-form-wrapper .project-form #ict-project-your-name' ).val();
    const clientPhone =
      jQuery( '.side-form-wrapper .project-form #ict-project-your-phone' ).val();

    jQuery( '.side-form-wrapper .project-form #ict-email-subject-project' )
      .val( `ICT Form Inquiry - ${clientName} - ${clientPhone}` );
  });

  jQuery( '.page-template-contact .project-form #project-your-name,' +
    ' .page-template-contact .project-form #project-your-phone' ).change( ( )=>{

    const clientName = jQuery( '.page-template-contact .project-form #project-your-name' ).val();
    const clientPhone = jQuery( '.page-template-contact .project-form #project-your-phone' ).val();
    jQuery( '.page-template-contact .project-form #email-subject-project' )
      .val( `Contact Page Form Inquiry - ${clientName} - ${clientPhone}` );
  });

};

let submitContactForm = () => {

  jQuery( '#contact-form' ).on( 'submit', function( event ) {

    let isValid = true;
    const apiUrl = jQuery( this ).attr( 'data-action' );
    const dataArr = jQuery( this ).serializeArray();

    event.preventDefault();

    jQuery( '#contact-form .control-wrap' ).removeClass( 'invalid' );

    jQuery( '#contact-form .ajax-loader' ).show();

    jQuery.each( dataArr, function( index, field ) {

      if ( ! field.value ) {
        isValid = false;
        jQuery( '#contact-form .control-wrap.' + field.name ).addClass( 'invalid' );
      }

      if ( 'your-email' === field.name && field.value ) {

        if ( ! validateEmail( field.value ) ) {
          isValid = false;
          jQuery( '#contact-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

      if ( 'your-phone' === field.name && field.value ) {

        if ( ! validatePhone( field.value ) ) {
          isValid = false;
          jQuery( '#contact-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

    });

    if ( isValid ) {

      const datastring = jQuery( this ).serialize(); // Use String to send data.

      jQuery.ajax({
        type:        'POST',
        url:         apiUrl,
        contentType: 'application/x-www-form-urlencoded',
        data:        datastring,
        success:     function( ) {
          jQuery( '#contact-form .ajax-loader' ).hide();
          jQuery( '#contact-form .response-output' )
            .text( 'Your message was sent successfully. Thanks - we\'ll be in touch shortly.' )
            .removeClass( 'error' ).addClass( 'success' );
          jQuery( '#contact-form' ).trigger( 'reset' ); // Clear Form after success.
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'form_submit',
            'form':  'footer'
          });
        },
        error: function() {
          jQuery( '#contact-form .ajax-loader' ).hide();
          jQuery( '#contact-form .response-output' )
            .text( 'Sorry, your message could not be sent. ' +
              'Please confirm the spam capture box is ticked and ' +
              'all required information has been entered correctly, and try again. ' +
              'Alternatively, send us a direct email or give us a bell, ' +
              'we love a friendly phone call!' )
            .removeClass( 'success' ).addClass( 'error' );
        }
      });

    } else {
      jQuery( '#contact-form .ajax-loader' ).hide();
      jQuery( '#contact-form .response-output' )
        .text( 'Sorry, your message could not be sent. ' +
          'Please confirm the spam capture box is ticked and ' +
          'all required information has been entered correctly, and try again. ' +
          'Alternatively, send us a direct email or give us a bell, ' +
          'we love a friendly phone call!' )
        .removeClass( 'success' )
        .addClass( 'error' );
      return;

    }

  });

};

let submitProjectForm = () => {

  jQuery( '#project-form' ).on( 'submit', function( event ) {

    let isValid = true;
    let apiUrl = jQuery( this ).attr( 'data-action' );
    const dataArr = jQuery( this ).serializeArray();
    event.preventDefault();

    jQuery( '#project-form .control-wrap' ).removeClass( 'invalid' );
    jQuery( '#project-form .ajax-loader' ).show();

    jQuery.each( dataArr, function( index, field ) {

      if ( 'your-email' === field.name ||
        'your-name' === field.name ||
        'your-phone' === field.name ||
        'services-of-interest' === field.name ||
        'g-recaptcha-response' === field.name ) {

        if ( ! field.value ) {
          isValid = false;
          jQuery( '#project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

      if ( 'your-email' === field.name && field.value ) {

        if ( ! validateEmail( field.value ) ) {
          isValid = false;
          jQuery( '#project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

      if ( 'your-phone' === field.name && field.value ) {

        if ( ! validatePhone( field.value ) ) {
          isValid = false;
          jQuery( '#project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

    });

    if ( isValid ) {

      const datastring = jQuery( this ).serialize(); // Use String to send data.

      jQuery.ajax({
        type:        'POST',
        url:         apiUrl,
        contentType: 'application/x-www-form-urlencoded',
        data:        datastring,
        success:     function( ) {
          jQuery( '#project-form .ajax-loader' ).hide();
          jQuery( '#project-form .response-output' )
            .text( 'Your message was sent successfully. Thanks - we\'ll be in touch shortly.' )
            .removeClass( 'error' ).addClass( 'success' );
          jQuery( '#project-form' ).trigger( 'reset' ); // Clear Form after success.
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'form_submit',
            'form':  'project'
          });
        },
        error: function() {
          jQuery( '#project-form .ajax-loader' ).hide();
          jQuery( '#project-form .response-output' )
            .text( 'Sorry, your message could not be sent. ' +
              'Please confirm the spam capture box is ticked and ' +
              'all required information has been entered correctly, and try again. ' +
              'Alternatively, send us a direct email or give us a bell, ' +
              'we love a friendly phone call!' )
            .removeClass( 'success' ).addClass( 'error' );
        }
      });

    } else {

      jQuery( '#project-form .ajax-loader' ).hide();
      jQuery( '#project-form .response-output' )
        .text( 'Sorry, your message could not be sent. ' +
          'Please confirm the spam capture box is ticked and ' +
          'all required information has been entered correctly, and try again. ' +
          'Alternatively, send us a direct email or give us a bell, ' +
          'we love a friendly phone call!' )
        .removeClass( 'success' )
        .addClass( 'error' );
      return;

    }

  });

};

let submitICTProjectForm = () => {

  jQuery( '#ict-project-form' ).on( 'submit', function( event ) {

    let isValid = true;
    let apiUrl = jQuery( this ).attr( 'data-action' );
    const dataArr = jQuery( this ).serializeArray();
    event.preventDefault();

    jQuery( '#ict-project-form .control-wrap' ).removeClass( 'invalid' );
    jQuery( '#ict-project-form .ajax-loader' ).show();

    jQuery.each( dataArr, function( index, field ) {

      if ( 'your-email' === field.name ||
        'your-name' === field.name ||
        'your-phone' === field.name ||
        'services-of-interest' === field.name ||
        'g-recaptcha-response' === field.name ) {

        if ( ! field.value ) {
          isValid = false;
          jQuery( '#ict-project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

      if ( 'your-email' === field.name && field.value ) {

        if ( ! validateEmail( field.value ) ) {
          isValid = false;
          jQuery( '#ict-project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

      if ( 'your-phone' === field.name && field.value ) {

        if ( ! validatePhone( field.value ) ) {
          isValid = false;
          jQuery( '#ict-project-form .control-wrap.' + field.name ).addClass( 'invalid' );
        }

      }

    });

    if ( isValid ) {

      const datastring = jQuery( this ).serialize(); // Use String to send data.

      jQuery.ajax({
        type:        'POST',
        url:         apiUrl,
        contentType: 'application/x-www-form-urlencoded',
        data:        datastring,
        success:     function( ) {
          jQuery( '#ict-project-form .ajax-loader' ).hide();
          jQuery( '#ict-project-form .response-output' )
            .text( 'Your message was sent successfully. Thanks - we\'ll be in touch shortly.' )
            .removeClass( 'error' ).addClass( 'success' );
          jQuery( '#ict-project-form' ).trigger( 'reset' ); // Clear Form after success.
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'form_submit',
            'form':  'project'
          });
        },
        error: function() {
          jQuery( '#ict-project-form .ajax-loader' ).hide();
          jQuery( '#ict-project-form .response-output' )
            .text( 'Sorry, your message could not be sent. ' +
              'Please confirm the spam capture box is ticked and ' +
              'all required information has been entered correctly, and try again. ' +
              'Alternatively, send us a direct email or give us a bell, ' +
              'we love a friendly phone call!' )
            .removeClass( 'success' ).addClass( 'error' );
        }
      });

    } else {

      jQuery( '#ict-project-form .ajax-loader' ).hide();
      jQuery( '#ict-project-form .response-output' )
        .text( 'Sorry, your message could not be sent. ' +
          'Please confirm the spam capture box is ticked and ' +
          'all required information has been entered correctly, and try again. ' +
          'Alternatively, send us a direct email or give us a bell, ' +
          'we love a friendly phone call!' )
        .removeClass( 'success' )
        .addClass( 'error' );
      return;

    }

  });

};

jQuery( document ).ready( function() {
  formFieldFocus();
  getDeviceType();
  updateContactFormTitle();
  submitContactForm();
  submitProjectForm();
  submitICTProjectForm();
});
