/*
 * IE exc. Edge detection tools. Uses the 'bowser' library to
 * set attributes on the <html> element for easy selection in both CSS and JS.
 *
 * @see https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery
 * @author Tong Wu <tong.wu@chromatix.com.au>
 */

/**
* detect IE
* returns version of IE or false, if browser is not Internet Explorer
*/
const NO_EXIST = 0;
const RADIX = 10;
const OLD_IE_VERSION_POS = 5;
const IE_11__VERSION_POS = 3;

const detectIE = () => {
  const ua = window.navigator.userAgent;

  const msie = ua.indexOf( 'MSIE ' );
  if ( NO_EXIST < msie ) {

    // IE 10 or older => return version number
    return parseInt( ua.substring( msie + OLD_IE_VERSION_POS, ua.indexOf( '.', msie ) ), RADIX );
  }

  const trident = ua.indexOf( 'Trident/' );
  if ( NO_EXIST < trident ) {

    // IE 11 => return version number
    const rv = ua.indexOf( 'rv:' );
    return parseInt( ua.substring( rv + IE_11__VERSION_POS, ua.indexOf( '.', rv ) ), RADIX );
  }

  // Other browser
  return false;
};

jQuery( () => {

  if ( detectIE() ) {
    jQuery( 'html' ).addClass( 'ie ie-' + detectIE() );
  }

});
