/* New function start */

const extendMagnificIframe = () => {

  let $iframe = {
    markup: '<div class="mfp-iframe-scaler">' +
      '<div id="iframeId" class="mfp-close"></div>' +
      '<iframe class="mfp-iframe mfp-fade" allow="autoplay; fullscreen" allowfullscreen></iframe>' +
      '</div>' +
      '<div class="mfp-bottom-bar">' +
      '<div class="mfp-title"></div>' +
      '</div>',
    patterns: {
      vimeo: {
        index: 'vimeo.com/',
        id: function( url ) {
          let match =
            url.match( /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/ );
          if ( ! match || ! match[5]) {
            return null;
          }
          return match[5];
        },
        src: 'https://player.vimeo.com/video/%id%?autoplay=1'
      }
    }
  };

  return $iframe;

};

const videoPopUp = ( () => {

  jQuery( '.chr-video-poster, .button--play-video' ).each( ( index, el )=>{

    jQuery( el ).magnificPopup({
      type:   'iframe',
      iframe: extendMagnificIframe(),
    });

  });

});

/* New function end */

jQuery( document ).ready( () => {
  videoPopUp();
});
