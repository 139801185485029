let defaultStartPoint = 0;

jQuery.fn.extend({

  /*
  *  @st: scrollTop
  *  @windowHeight: Window Height
  *  @startPoint : the distance between the element top and this point
  */

  fadeOnView: function( st, windowHeight, startPoint = defaultStartPoint ) {

    let offsetTop, pos;

    if ( jQuery( this ).length ) {

      offsetTop = jQuery( this ).offset().top;
      pos = st + windowHeight - startPoint;

      if ( ! jQuery( this ).hasClass( 'show' ) ) {

        if ( offsetTop < pos ) {
          jQuery( this ).addClass( 'show' );
        }

      }

    }

  }

});

jQuery.fn.animateRotate = function( startAngle, endAngle, duration, complete ) {

  return this.each( function() {

    var elem = jQuery( this );

    jQuery({ deg: startAngle }).animate({ deg: endAngle }, {

      duration: parseInt( duration ),
      easing:   'swing',
      step:     function( now ) {
        elem.css({
          'transform': 'rotate(' + now + 'deg)'
        });
      },
      complete: complete || $.noop
    });
  });
};
