const bodyScrollLock = require( 'body-scroll-lock' );
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

const targetElement =
  document.querySelector( '.side-form-wrapper .right-part' );

const toggleSlideOutFormBtn = () => {

  const half = 0.5;
  let st = jQuery( window ).scrollTop();
  let bannerHeight = 0;
  let headerHeight = jQuery( '.chr-header' ).height();
  let $homeBanner = jQuery( '.home-banner' );
  let $subPageBanner = jQuery( '.chr-banner' );
  let footerOffsetTop = jQuery( '.chr-footer' ).offset().top;
  let windowHeight = jQuery( window ).height();

  if ( $homeBanner.length ) {
    bannerHeight = $homeBanner.height() * half;
  } else if ( $subPageBanner.length ) {
    bannerHeight = $subPageBanner.height();
  }

  if ( st <= bannerHeight * half - headerHeight ) {
    jQuery( '.side-form-open-btn' ).removeClass( 'show' );
  } else if ( st <= footerOffsetTop - windowHeight * half ) {
    jQuery( '.side-form-open-btn' ).addClass( 'show' );
  } else {
    jQuery( '.side-form-open-btn' ).removeClass( 'show' );
  }

};

const numberIncrementForICT = () => {

  jQuery( '.ict-icon-list .item-number' ).each( ( index, el ) => {

    let value = jQuery( el ).attr( 'data-number' ),
        $el = jQuery( el ).find( '.number' );

    $({value: 0}).stop( true ).animate({value: value}, {
      duration: 3000,
      easing:   'swing',
      step:     function() {
        let val = Math.round( this.value );
        $el.text( val );
      }
    }).promise().done( () => {

      // Hard set the value after animation is done to be
      // Sure the value is correct
      $el.text( value );
    });
  });

};

const openSideFormHandler = () => {

  jQuery( 'body' ).addClass( 'side-form-opened' );
  disableBodyScroll( targetElement );
  numberIncrementForICT();

};

const openSideFormTagClickHandler = () => {
  openSideFormHandler();
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'tag_click'
  });
};

const openSideForm = () => {
  jQuery( '.side-form-open-trigger' ).on( 'click', openSideFormHandler );
  jQuery( '.side-form-open-btn' ).on( 'click', openSideFormTagClickHandler );
};

const closeSideFormHandler = () => {
  jQuery( 'body' ).removeClass( 'side-form-opened' );
  enableBodyScroll( targetElement );
};

const closeSideForm = () => {

  jQuery( '.chr-close-btn, .side-form-black-overlay' ).on( 'click', closeSideFormHandler );

};

const formFocusHandler = () => {

  jQuery( '.side-form-wrapper input, .side-form-wrapper textarea' ).focus( () => {
    disableBodyScroll( targetElement );
  });

};

const mouseMoveShowToucan = () => {

  const triggerPoint = 0.66;

  jQuery( 'body' ).mousemove( ( event ) => {

    if ( ( event.pageX / jQuery( window ).width() ) > triggerPoint ) {
      jQuery( '.side-form-section .side-form-open-btn' ).addClass( 'hover' );
    } else {
      jQuery( '.side-form-section .side-form-open-btn' ).removeClass( 'hover' );
    }

  });

};

jQuery( () => {
  openSideForm();
  closeSideForm();
  formFocusHandler();
  mouseMoveShowToucan();

  /*Handle some scroll events*/
  jQuery( window ).scroll( () => {
    toggleSlideOutFormBtn();
  }).scroll();

});
