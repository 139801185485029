let screenSize = require( '../utilities/media' );

let footerSideImgParallax = () => { // Toucan Move

  let windowHeight = jQuery( window ).height();
  let st = jQuery( window ).scrollTop();
  let tweakPoint = 500;

  jQuery( 'footer .footer-image-wrapper' ).each( function() {

    let sectionTop = jQuery( this ).parent().offset().top - tweakPoint;
    let speed = jQuery( this ).attr( 'data-speed' );
    let startPostion = -windowHeight * speed;
    let imgPosition = 0;

    if ( st + windowHeight > sectionTop &&
      st < sectionTop + jQuery( this ).parent().parent().height() ) {

      imgPosition = -( startPostion +
        ( st + windowHeight - sectionTop ) * speed );
      jQuery( this ).css( 'transform', 'translate3d(0, ' + imgPosition + 'px, 0)' );
    }

  });

};

jQuery( () => {

  if ( ! matchMedia( screenSize.desktop ).matches ) {
    return;
  }

  jQuery( window ).scroll( function() {

    footerSideImgParallax();

  }).scroll();

});
