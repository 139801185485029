/*
 * Disable eslint, coz it is callback function, used on project form PHP file.
 * Function is used for fix page jump to bottom after click google reCaptcha on IOS device
 */

/* eslint-disable */
let screenSize = require( '../utilities/media' );

const chr_onReCaptchaSuccess = () => {

  if (matchMedia(screenSize.desktop).matches) {
    return;
  }

  if ( /iPhone|iPad/.test( navigator.userAgent ) && ! window.MSStream ) {
    const windowH = window.innerHeight;
    const recapthcaH = jQuery( '#project-form .g-recaptcha' ).height();
    const animateTime = 500;
    const destElementOffset = jQuery( '#project-form .g-recaptcha' ).offset().top -
      windowH + recapthcaH;
    jQuery( 'html, body' ).animate({ scrollTop: destElementOffset }, animateTime );
  }

}
window.chr_onReCaptchaSuccess = chr_onReCaptchaSuccess;

const chr_onReCaptchaSuccessICT = () => {

  if (matchMedia(screenSize.desktop).matches) {
    return;
  }

  if ( /iPhone|iPad/.test( navigator.userAgent ) && ! window.MSStream ) {
    const windowH = window.innerHeight;
    const recapthcaH = jQuery( '#ict-project-form .g-recaptcha' ).height();
    const animateTime = 500;
    const destElementOffset = jQuery( '#ict-project-form .g-recaptcha' ).offset().top -
      windowH + recapthcaH;
    jQuery( 'html, body' ).animate({ scrollTop: destElementOffset }, animateTime );
  }

}
window.chr_onReCaptchaSuccessICT = chr_onReCaptchaSuccessICT;
/* eslint-enable */

